<template>
    <div>
        <el-carousel :interval="5000" arrow="always" class="head-picture" height="98rem" width="185rem">
            <el-carousel-item v-for="item in headImgList" :key="item.index" >
                <img class="carousel-img" :src="item.url">
                <div class="carousel-head-content" style="white-space: pre-wrap;">
                    <div class="title1">{{item.title1}}</div>
                    <div class="title2">{{item.title2}}</div>
                    <div class="title3">{{item.title3}}</div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesIndexCarousel',

    data() {
        return {
            headImgList:[
                {
                    index: 1,
                    url:require("../../assets/image/index/1bigBanner.png"),
                    title1:'科技护卫健康',
                    title2:'梦天门',
                    title3:'公共卫生与医疗健康智慧信息服务专家;\n在医疗健康行业，为客户提供专业且快速见效的解决方案',
                },
                {
                    index: 2,
                    url:require("../../assets/image/index/2bigBanner.png"),
                    title1:'诚信于人・精心于事',
                    title2:'数字化生产管理平台',
                    title3:'专业服务于中国公共卫生产业信息化，\n创新智慧疾控新模式，\n打造数字疾控新蓝图。',
                },
                {
                    index: 3,
                    url:require("../../assets/image/index/3bigBanner.jpg"),
                    title1:'公卫体检·创新融合',
                    title2:'基于用户画像的一体化健康体检平台',
                    title3:'融合公共场所、食品卫生、化妆品、有害生物防治、家政服务等公卫从业人员和医师、药师、特殊职业从业人员健康体检信息，\n提供健康体检预约、健康证明查询、灵活用工推介等综合性服务。',
                }
            ],
        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
.carousel-img{
    width:100%;
    height:100%;
    transition: all 1s;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 1.8rem;
    opacity: 0.75;
    line-height: 30rem;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
/deep/ .el-carousel__indicator--horizontal{
    padding: 28.7rem 0.4rem;
}
  .carousel-head{
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    border-radius: 0.5rem;
    margin: 0.5rem;
    padding: 0 0 0.8rem 0;
    height: 15rem;
    border: darkgray 1px solid;
    .headContainerBackground{
        width: 100%;
        height: 50%;
        position: relative;
        overflow: hidden;
        padding: 1rem 1rem 0 1rem;
        border-radius: 0 0 50% 50%;
        background: #1ad17a;
    }
  }
  .carousel-head-content{
    position: absolute;
    margin: -72.6rem 0 0 47.6rem;
    z-index: 99;
    width: 96.7rem;
    .title1{
        height: 4.8rem;
        font-size: 5rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 4.8rem;
        transition: all 1s;
    }
    .title2{
        // width: 75.5rem;
        height: 4.8rem;
        font-size: 3.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 4.8rem;
        margin: auto;
        margin-top: 1.7rem;
    }
    .title3{
        width: 96.7rem;
        height: 11.8rem;
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 5rem;

        margin: auto;
        margin-top: 6.8rem;
    }
  }
</style>
