<template>
    <div>
        <indexCarousel></indexCarousel>
        <div class="container-1">
            <div class="container-title" style="padding-top: 7.8rem;">行业方案</div>
            <div class="container-content" style="padding-top: 1rem;">/THE SOLUTION</div>
            <div class="box-1" style="margin-top:3.9rem">
                <div class="box-content">
                    <div class="box-content-title">监督执法</div>
                    <div class="box-content-content">智慧卫监一体化、统一物联网监管、医疗服务综合监管、执法全过程记录、智慧食药监督、明厨亮灶等解决方案</div>
                    <button class="box-content--botton">了解详情</button>
                </div>
                <div class="box-content">
                    <img class="box-content-img" src="../assets/image/index/01-1监督执法.png"/>
                </div>
            </div>
            <div class="box-1" style="margin-top:3rem;margin-bottom: 8rem;">
                <div class="box-content">
                    <img class="box-content-img" src="../assets/image/index/01-1疾病控制.png"/>
                </div>
                <div class="box-content">
                    <div class="box-content-title">疾病控制</div>
                    <div class="box-content-content">传染病智慧化多点预警监测、区域死因监测与报告 、数字化流行病学调查、 疾控信息资源整合分析等解决方案</div>
                    <button class="box-content--botton">了解详情</button>
                </div>
            </div>
            <div class="box-1" style="margin-top:3.9rem">
              <div class="box-content">
                <div class="box-content-title">健康体检</div>
                <div class="box-content-content">公共场所、食品卫生、家政护理等公卫从业人员健康体检及药师、教师、特定职业人员健康体检信息化综合解决方案</div>
                <button class="box-content--botton">了解详情</button>
              </div>
              <div class="box-content">
                <img class="box-content-img" src="../assets/image/index/01-1健康体检.png"/>
              </div>
            </div>
            <div class="box-1" style="margin-top:3rem;margin-bottom: 8rem;">
              <div class="box-content">
                <img class="box-content-img" src="../assets/image/index/01-1医防协同.png"/>
              </div>
              <div class="box-content">
                <div class="box-content-title">医防协同</div>
                <div class="box-content-content">医防协同、医养协同、全民健康、公卫服务、医疗服务等信息化解决方案</div>
                <button class="box-content--botton">了解详情</button>
              </div>
            </div>
        </div>
        <div class="container-2">
            <div class="container-title" style="padding-top: 6rem;">科技护卫健康</div>
            <div class="container-content" style="padding-top: 1.1rem;height: 8.9rem">公共卫生与医疗健康智慧信息服务专家</div>
            <div class="box-2">
                <div class="img-item">
                    <div class="img-trangle1"></div>
                    <img style="width:27.7rem;height:30.3rem" :src="box2ImageList[box2Index].url" alt="">
                </div>
                <div class="img-item">
                    <div class="img-trangle2"></div>
                    <img style="width:25rem;height:24.9rem;margin: 5.3rem 0 0 1.9rem;" :src="box2ImageList[box2Index+1].url" alt="">
                </div>
                <div class="img-card">
                    <img :src="box2ImageList[box2Index+2].url" alt="">

                    <div class="img-item-title">{{box2ImageList[box2Index+2].title}}</div>
                    <div class="img-item-content">{{box2ImageList[box2Index+2].content}}</div>
                    <div class="img-item-more">查看全部</div>
                    <div class="left-botton" @click="box2ImageDecrease"><i class="el-icon-back icon-style"></i></div>
                    <div class="right-botton" @click="box2ImageIncrease"><i class="el-icon-right icon-style"></i></div>
                </div>
                <div class="img-item" >
                    <div class="img-trangle3"></div>
                    <img style="width:25rem;height:24.9rem;margin: 5.3rem 0 0 1.8rem;" :src="box2ImageList[box2Index+3].url" alt="">
                </div>
                <div class="img-item">
                    <div class="img-trangle4"></div>
                    <img style="width:27.7rem;height:30.3rem;margin: 0rem 0 0 1.9rem;" :src="box2ImageList[box2Index+4].url" alt="">
                </div>
            </div>
        </div>
        <div class="container-3">
            <div class="container-title" style="padding-top: 7.8rem;">技术服务</div>
            <div class="container-content" style="padding-top: 1.1rem;">提供从咨询设计到开发落地运维的完整技术服务</div>
            <div class="box-3">
                <div class="box3-card">
                    <img src="../assets/image/index/01-1数据湖仓技术.png" class="box3-card-img" alt="">
                    <div class="box3-card-title">数据湖仓技术</div>
                    <div class="box3-card-content">梦天门致力于帮助企业构建数据湖，实现核心流程数字化、自助式商业智能、BI移动化等服务，推动企业数字化转型。</div>
                    <div class="box3-card-more">了解详情</div>
                </div>
                <div class="box3-card">
                    <img src="../assets/image/index/01-1阿里云.png" class="box3-card-img" alt="">
                    <div class="box3-card-title">实施及运维</div>
                    <div class="box3-card-content">作为阿里云的合作伙伴，梦天门凭借着对信息技术趋势的洞察，丰富的行业经验，以及全方位的服务，协助客户进行数字化转型、云部署和技术创新……</div>
                    <div class="box3-card-more">了解详情</div>
                </div>
                <div class="box3-card">
                    <img src="../assets/image/index/01-1定制开发及运维.png" class="box3-card-img" alt="">
                    <div class="box3-card-title">定制开发</div>
                    <div class="box3-card-content">梦天门科技凭精湛的研发技术，以及在健康，医疗,医药行业数字化转型上积累的丰富经验， 可为客户提供各类业务系统的定制化开发服务。</div>
                    <div class="box3-card-more">了解详情</div>
                </div>
            </div>
        </div>
<!--        <div class="container-4">
            <div class="container-title" style="padding-top: 6rem;">新闻资讯</div>
            <el-carousel indicator-position="outside">
                    <el-carousel-item v-for="item in box4List" :key="item.index">
                        <div class="container4-carousel">
                            <img :src="item.url" class="container4-carousel-img">
                            <div class="box4">
                                <div class="container4-carousel-title">{{item.title}}</div>
                                <div class="container4-carousel-date">{{item.date}}</div>
                                <div class="container4-carousel-content">{{item.content}}</div>
                                <div class="container4-carousel-more">阅读更多<i class="el-icon-right" style="margin-top:0.8rem"></i></div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
        </div>-->
        <div class="container-5">
            <div class="container-title" style="padding-top: 8rem;">企业文化</div>
            <div class="box5">
<!--                <div class="title">客户摘选</div>-->
                <img src="../assets/image/index/企业文化.png" class="box10-img">
<!--                <div class="title" style="margin-top:4rem">合作伙伴</div>
                <img src="../assets/image/index/01-1合作伙伴.png" class="box5-img">-->
            </div>
        </div>
    </div>
</template>

<script>
import indexCarousel from '../components/index-carousel/index-carousel.vue'
export default {
    name: 'BizfocusPagesIndex',
    components:{indexCarousel},
    data() {
        return {
            box2Index: 0,
            headImgList:[
                {
                    index: 1,
                    url:require("../assets/image/index/1bigBanner.png")
                },
                {
                    index: 2,
                    url:require("../assets/image/index/2bigBanner.png")
                },
                {
                    index: 3,
                    url:require("../assets/image/index/3bigBanner.jpg")
                }
            ],
            box2ImageList:[
                {
                    index:1,
                    url:require("../assets/image/index/01-1智慧医疗数字化.jpeg"),
                    title:'智慧医疗数字化',
                    content:'对医疗数据进行整合和分析，提高医疗服务的数据质量和效率'
                },
                {
                    index:2,
                    url:require("../assets/image/index/01-1智慧医疗网络化.jpg"),
                    title:'智慧医疗网络化',
                    content:'为医疗工作提供便捷的数据支持，提供更精准的诊断和治疗方案，使成效得到大幅提升'
                },
                {
                    index:3,
                    url:require("../assets/image/index/01-1物联网技术.jpeg"),
                    title:'物联网技术',
                    content:'实现对病人生命体征的实时监测，在病情恶化时自动发出警报，及时通知医护人员进行干预'
                },
                {
                    index:4,
                    url:require("../assets/image/index/01-1人工智能技术.jpeg"),
                    title:'人工智能技术',
                    content:'对医疗数据进行深度分析和挖掘，提高诊断的准确性和治疗的有效性，减少医疗差错和事故'
                },
                {
                    index:5,
                    url:require("../assets/image/index/01-1互联网医院优势性.jpg"),
                    title:'互联网医院优势性',
                    content:'患者可以通过手机、电脑等设备进行在线咨询和预约，避免了排队等待和交通拥堵等问题'
                },
                {
                    index:6,
                    url:require("../assets/image/index/01-1降低医疗成本.jpeg"),
                    title:'降低医疗成本',
                    content:'通过智能化的医疗管理系统和电子病历系统，实现医疗信息的共享和流通，避免了重复检查和治疗'
                },
                {
                    index:6,
                    url:require("../assets/image/index/01-1促进医疗科研和教育的发展.jpeg"),
                    title:'促进医疗教育科研发展',
                    content:'通过智能化的医疗设备和数据分析系统，收集和分析大量的医疗数据，为医学研究提供宝贵的资源和参考'
                },
            ],
            box4List:[
                {
                    index: 1,
                    title:'第三个3年，梦天门再次通过ISO9001认证',
                    date:'2022/11/04',
                    content:'2022年11月3日，北京梦天门科技股份有限公司再一次顺利通过了ISO9001的认证。 ISO9001证书有效期为3年，从2014年开始，这已经是我们第三个3年了。 在此认证审核的过程中，我们自上而下，从公司，部门到项目，展示了各层级的整体目标及相应的质量标准，实施方案和成果。',
                    url:require("../assets/image/index/01-1第三个3年.png")
                },
                {
                    index: 2,
                    title:'正在筹办的XX活动',
                    date:'2022/11/30',
                    content:'这里是新闻的详细内容，这里是新闻的详细内容，这里是新闻的详细内容。这里是新闻的详细内容，这里是新闻的详细内容，这里是新闻的详细内容，这里是新闻的详细内容，这里是新闻的详细内容，这里是新闻的详细内容，这里是新闻的详细内容。',
                    url:require("../assets/image/index/5-0SAP乘云而上.png")
                },
                {
                    index: 3,
                    title:'惊心动魄，可口可乐SCMC的“黄金24小时”',
                    date:'2022/10/26',
                    content:'随着疫情的常态化发展，近年来，越来越多企业选择将IT架构，重要应用、关键数据等核心业务迁移至云端，由于数据对企业发展有着至关重要的作用，因此在选择云平台时，会优先考虑云平台的安全性，提高业务运维的灵活性和弹性。一旦出现突发问题，而运维人员和关键组件无法第一时间到达到场，将会给企业业务带来极大风险。',
                    url:require("../assets/image/index/05-0可乐24小时.png")
                }
            ]

        };
    },

    mounted() {

    },

    methods: {
        box2ImageIncrease(){
            const data = [];
            this.box2ImageList.forEach((item, index) => {
                if(index > 0){
                    data[index - 1] = item
                }
            })
            data[data.length] = this.box2ImageList[0]
            this.box2ImageList = data
        },
        box2ImageDecrease(){
            const data = [];
            data[0] = this.box2ImageList[this.box2ImageList.length-1]
            for(let i = this.box2ImageList.length -1; i > 0; i--){
                data[i] = this.box2ImageList[i-1]
            }
            this.box2ImageList = data

        }
    },
};
</script>

<style lang="less" scoped>
.img-trangle1{
    position: absolute;
    width: 0;
    height: 0;
    border-top:0px solid transparent;
    border-left: 0px solid #000;
    border-right: 27.7rem solid #F8F8F8 ;
    border-bottom: 5.3rem solid transparent;
}
.img-trangle2{
    position: absolute;
    width:25rem;
    height:24.9rem;
    margin: 5.3rem 0 0 1.9rem;
    width: 0;
    height: 0;
    border-top:0px solid transparent;
    border-left: 0px solid #000;
    border-right: 25rem solid #F8F8F8 ;
    border-bottom: 2rem solid transparent;
}
.img-trangle3{
    position: absolute;
    width:25rem;
    height:24.9rem;
    margin: 5.3rem 0 0 1.8rem;
    width: 0;
    height: 0;
    border-top:0px solid transparent;
    border-left: 25rem solid #F8F8F8;
    border-right: 0rem solid transparent ;
    border-bottom: 2rem solid transparent;
}
.img-trangle4{
    position: absolute;
    width: 0;
    height: 0;
    margin: 0rem 0 0 1.8rem;
    border-top:0px solid transparent;
    border-left: 27.7rem  solid #F8F8F8;
    border-right: 0px solid transparent ;
    border-bottom: 5.3rem solid transparent;
}
</style>
